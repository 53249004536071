<template>
  <TransitionRoot appear :show="delete_comment_modal" as="template">
    <Dialog
      as="div"
      @close="closeModal"
      class="fixed inset-0 z-50 overflow-y-auto"
    >
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black bg-opacity-25" />
      </TransitionChild>

      <div class="min-h-screen px-1 text-center md:px-4">
        <div
          class="fixed inset-0 bg-neutral-900 bg-opacity-50 dark:bg-opacity-80 opacity-100 flex items-center justify-center"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="inline-block w-full my-5 overflow-hidden text-left align-middle transition-all transform bg-white border border-black border-opacity-5 shadow-xl rounded-2xl sm:my-8 dark:bg-neutral-800 dark:border-neutral-700 text-neutral-900 dark:text-neutral-300 max-w-screen-md opacity-100 scale-100"
            >
              <DialogTitle
                as="div"
                class="py-4 px-6 text-center relative border-b border-neutral-100 dark:border-neutral-700 md:py-5"
              >
                <button
                  class="w-8 h-8 flex items-center justify-center rounded-full text-neutral-700 dark:text-neutral-300 hover:bg-neutral-100 dark:hover:bg-neutral-700 absolute left-2 top-1/2 transform -translate-y-1/2 sm:left-4 focus:outline-none"
                  @click="delete_comment_modal = false"
                >
                  <span class="sr-only">{{$t('close')}}</span>
                  <IconX class="w-5 h-5" />
                </button>
              </DialogTitle>
              <div class="py-4 px-6 md:py-5">
                <form @submit.prevent="DeleteComment">
                  <h3
                    class="text-lg font-semibold text-neutral-900 dark:text-neutral-200"
                  >
                    {{$t('comment.delete_title')}}
                  </h3>
                  <span class="text-sm"
                    >{{$t('comment.delete_prompt')}}</span
                  >
                  <div class="mt-4 space-x-3">
                    <button
                      class="py-1 px-3 sm:py-1.5 sm:px-4 flex-shrink-0 relative h-auto inline-flex items-center justify-center rounded-full transition-colors border-transparent bg-red-500 hover:bg-red-400 text-teal-50 text-sm sm:text-base font-medium"
                      type="submit"
                    >
                      {{$t('submit')}}
                    </button>
                    <button
                      @click="delete_comment_modal = false"
                      class="py-1 px-3 sm:py-1.5 sm:px-4 flex-shrink-0 relative h-auto inline-flex items-center justify-center rounded-full transition-colors border-transparent bg-neutral-200 dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200 text-sm sm:text-base font-medium"
                      type="button"
                    >
                      {{$t('cancel')}}
                    </button>
                  </div>
                </form>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script lang="ts" setup>
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/vue";
import { storeToRefs } from "pinia";
import { useHotelStore } from "~/stores/hotel";
const { delete_comment_modal, comment } = storeToRefs(useHotelStore());
function closeModal() {
  delete_comment_modal.value = false;
}
const emit = defineEmits(["DeleteComment"]);
const DeleteComment = () => {
  emit("DeleteComment");
};
</script>
