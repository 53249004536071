<template>
  <div v-if="post">
    <div class="pt-16 relative z-10 md:py-20 lg:py-28 bg-neutral-900 dark:bg-neutral-950">
      <BlogPostHeader :post="post" />
    </div>
    <div class="container flex flex-col my-10 py-6 bg-white dark:bg-transparent rounded-md lg:flex-row">
      <div class="w-full lg:w-3/5 xl:w-2/3 xl:pr-20">
        <ClientOnly>
          <component :is="'style'" v-html="global_styles"></component>
        </ClientOnly>
        <div class="relative space-y-10">
          <BlogPostToc :menus="post.menus"></BlogPostToc>
          <BlogPostAds></BlogPostAds>
          <div id="single-entry-content" class="prose md:prose-base max-w-full mx-auto dark:prose-invert">
            <template v-for="(block, i) in post.blocks">
              <div class="blocks-content" v-html="block.raw"></div>
            </template>
          </div>
          <BlogPostTag :post="post" />
          <div class="max-w-screen-md border-b border-t border-neutral-100 dark:border-neutral-700">
            <CoreComments v-if="post && post.comments" :type="'post'" :entity_id="post.ID" :comments="post.comments"
              :count_comments="post.total_comments" @AddComment="AddComment" @DeleteComment="DeleteComment"
              @EditComment="EditComment" @CreateComment="CreateComment" @eventLoadComments="GetComments"
              :loading="loading" />
          </div>
        </div>
      </div>
      <BlogPostSidebar />
    </div>
    <BlogPostRelate :posts="post.post_related" />
  </div>
</template>
<script lang="ts" setup>
import { storeToRefs } from "pinia";
import { useCmsStore } from "~/stores/cms";
import { useHotelStore } from "~/stores/hotel";
import { type Comment } from "~/types/Comment";
import { type Post } from "~/types/Post";

definePageMeta({
  middleware: ["global-styles"],
});
useHead({
  link: [{ rel: "stylesheet", href: "/css/block-library_style.min.css" }],
});

const route = useRoute();
const error = useError();
const { locale, t } = useI18n();
const { global_styles } = storeToRefs(useCmsStore());
const { data: post } = await useAsyncData(async () => {
  try {
    const res: Post = await $fetch("/api/cms/post/" + route.params.slug, {
      method: "POST",
      body: {
        locale: locale.value,
      },
    });
    if (res.ID) {
      const res2: { comments: Comment[]; total_comments: number } =
        await $fetch("/api/cms/comments", {
          method: "post",
          body: {
            post_id: res.ID,
            post_id_locale: res.post_id_locale,
            page: 1,
          },
        });
      res.comments = res2.comments;
      res.total_comments = res2.total_comments;
    } else {
      res.comments = [];
      res.total_comments = 0;
    }
    return res;
  } catch (e) {
    error.value = { statusCode: 404, message: "Page not found", name: "Error" };
    return null;
  }
});

const { comment } = storeToRefs(useHotelStore());
const randomIndex = computed(() => {
  const index = post.value?.blocks.length;
  if (index) {
    const firstIndex = Math.floor(Math.random() * index);
    let secondIndex;
    do {
      secondIndex = Math.floor(Math.random() * index);
    } while (secondIndex === firstIndex);
    return [firstIndex, secondIndex];
  }
  return [1, 2];
});
const AddComment = (data: { comment_id: number; comment: Comment }) => {
  if (post.value?.comments?.length) {
    for (let i = 0; i < post.value?.comments.length; i++) {
      if (data.comment_id == post.value?.comments[i].id) {
        post.value.comments[i].children.push(data.comment);
        break;
      }
    }
  }
};
const DeleteComment = (data: any) => {
  if (data && post.value?.comments?.length) {
    for (let i = 0; i < post.value?.comments.length; i++) {
      const element = post.value?.comments[i];
      if (element.id == comment.value?.id) {
        post.value.comments = post.value?.comments.filter(
          (item) => item.id != comment.value?.id
        );
        break;
      }
      for (let j = 0; j < element.children.length; j++) {
        if (element.children[j].id == comment.value?.id) {
          post.value.comments[i].children = post.value?.comments[
            i
          ].children.filter((item) => item.id != comment.value?.id);
          break;
        }
      }
    }
  }
};
const EditComment = (content: string): any => {
  if (post.value?.comments?.length) {
    for (let i = 0; i < post.value?.comments.length; i++) {
      const cm = post.value?.comments[i];
      if (cm.id == comment.value?.id) {
        post.value.comments[i].content = content;
        break;
      }
      if (post.value.comments[i].children.length) {
        for (let j = 0; j < post.value.comments[i].children.length; j++) {
          const cmc = post.value.comments[i].children[j];
          if (cmc.id == comment.value?.id) {
            post.value.comments[i].children[j].content = content;
            break;
          }
        }
      }
    }
  } else {
  }
  return;
};
const CreateComment = (comRes: Comment) => {
  post.value?.comments?.unshift(comRes);
};
const page = ref(1);
const loading = ref(false);
const GetComments = () => {
  page.value++;
  loading.value = true;
  useApiFetch("/api/cms/comments", {
    method: "POST",
    body: {
      post_id: post.value?.ID,
      post_id_locale: post.value?.post_id_locale,
      page: page.value,
    },
  }).then((res) => {
    loading.value = false;
    if (res.data.value && post.value?.comments?.length) {
      const comRes = res.data.value as unknown as {
        comments: Comment[];
        total_comments: number;
      };
      post.value.comments = [...post.value.comments, ...comRes.comments];
      post.value.total_comments = comRes.total_comments;
    }
  });
};

route.meta.nuxtI18n = {
  vi: { slug: post.value?.slug_vi },
  en: { slug: post.value?.slug_en },
};

// metadata
const meta = useMeta();

var i18nHead = useLocaleHead({
  addSeoAttributes: {
    canonicalQueries: ["/"],
  },
});
if (post.value) {
  if (!post.value.slug_vi || !post.value.slug_en) {
    i18nHead.value.meta = [];
    i18nHead.value.link = [];
  }
}
const meta_tags = meta.metaTag({
  title: post.value?.post_title,
  description: post.value?.post_excerpt,
  url: meta.currentURL(),
});
const description = computed(() => {
  if (post.value?.post_excerpt) {
    return post.value?.post_excerpt
  }
  return post.value?.post_title
})
const dayjs = useDayjs();
useHead({
  title: post.value?.post_title,
  meta: [
    { name: "description", content: description.value },
    {
      name: "keywords",
      content: post.value?.tags
        ? post.value?.tags?.map((item) => item.name).join(",")
        : "",
    },
    {
      "property": "og:image",
      "content": post.value?.thumbnail
    },
    {
      "property": "og:image:width",
      "content": "1200"
    },
    {
      "property": "og:image:height",
      "content": "630"
    },
    {
      "property": "og:image:type",
      "content": "image/png"
    },
    {
      "name": "twitter:card",
      "content": "summary_large_image"
    },
    {
      "name": "twitter:image:src",
      "content": post.value?.thumbnail
    },
    {
      "name": "twitter:image:width",
      "content": "1200"
    },
    {
      "name": "twitter:image:height",
      "content": "630"
    },
    ...(i18nHead.value.meta || []),
    ...meta_tags,
  ],
  htmlAttrs: {
    lang: i18nHead.value.htmlAttrs!.lang,
  },
  link: [...(i18nHead.value.link || [])],
});

const commentsDefineMeta = computed(() => {
  if (post.value && post.value.comments?.length) {
    var jsondata = [];
    for (let i = 0; i < post.value.comments.length; i++) {
      const c = post.value.comments[i];
      jsondata.push(
        defineComment({
          text: c.content,
          author: {
            name: c.user?.full_name,
            image: c.user?.avatar_url,
          },
        })
      );
    }
    return jsondata.map((item) => {
      delete item._resolver;
      return item;
    });
  }

  return [];
});

useSchemaOrg([
  defineWebPage({
    name: post.value?.post_title,
    datePublished: dayjs(post.value?.post_date).toISOString(),
    dateModified: dayjs(post.value?.post_modified).toISOString(),
    description: post.value?.post_excerpt,
    inLanguage: meta.inLanguages(true),
    url: meta.currentURL(),
  }),
  defineArticle({
    "@type": "Article",
    name: post.value?.post_title,
    headline: post.value?.post_title,
    datePublished: dayjs(post.value?.post_date).toISOString(),
    dateModified: dayjs(post.value?.post_modified).toISOString(),
    description: post.value?.post_excerpt,
    url: meta.currentURL(),
    inLanguage: meta.inLanguages(true),
    comment: commentsDefineMeta.value,
    commentCount: post.value?.comment_counts || 0,
    author: definePerson({
      name: post.value?.author_name,
      image: post.value?.author_avatar,
      url:
        meta.currentHost() +
        useLocalePath()("/author/" + post.value?.author_username),
    }),
  }),
  defineBreadcrumb({
    itemListElement: [
      { item: { name: "Blog", "@id": meta.currentHost() + "/blog" } },
      {
        item: {
          name: post.value?.post_title,
          "@id": meta.currentURL(),
        },
      },
    ],
  }),
]);

</script>
