<template>
  <BlogPostSidebarTemp>
    <template #header> {{ $t("subscribe") }} </template>
    <div>
      <span
        class="mt-2 text-sm leading-6 text-neutral-600 dark:text-neutral-400"
        >{{ $t("sub_blog_msg") }}</span
      >
      <div class="mt-4">
        <form @submit.prevent="submit" class="relative">
          <div class="relative">
            <input
              type="email"
              class="block w-full border-neutral-200 bg-white hover:ring hover:ring-secondary-200/50 focus:border-secondary-300 focus:ring focus:ring-secondary-200/50 dark:border-neutral-600 dark:bg-transparent dark:hover:ring-secondary-500/30 dark:focus:ring-secondary-500/30 rounded-2xl text-sm font-normal h-11 px-4 py-3"
              required
              v-model="email"
              aria-required="true"
              :placeholder="$t('homepage.enter_email')"
            /><button
              class="flex items-center justify-center rounded-full bg-neutral-900 !leading-none text-neutral-50 hover:bg-neutral-800 disabled:bg-opacity-70 absolute end-1 top-1/2 -translate-y-1/2 transform dark:bg-neutral-300 dark:text-black w-9 h-9 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-600 dark:focus:ring-offset-0"
              type="submit"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                aria-hidden="true"
                data-slot="icon"
                class="h-5 w-5 rtl:rotate-180"
              >
                <path
                  fill-rule="evenodd"
                  d="M12.97 3.97a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 1 1-1.06-1.06l6.22-6.22H3a.75.75 0 0 1 0-1.5h16.19l-6.22-6.22a.75.75 0 0 1 0-1.06Z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
        </form>
      </div>
    </div>
  </BlogPostSidebarTemp>
</template>
<script lang="ts" setup>
const email = ref("");
const { locale } = useI18n();
const submit = () => {
  useApiFetch("/api/subscriber", {
    method: "POST",
    body: {
      locale: locale.value,
      email: email.value,
    },
  });
};
</script>
