<template>
  <header class="container relative z-10">
    <div class="max-w-screen-lg">
      <div class="space-y-5">
        <div class="flex flex-wrap gap-2">
          <NuxtLink
            v-for="category in post?.categories"
            :to="localePath('/topic/' + category.slug)"
            class="transition-colors duration-300 inline-flex px-4 text-sm py-1.5 items-center justify-center rounded-full font-medium text-secondary-800 bg-neutral-100 hover:bg-secondary-100"
          >
            <span v-html="category.name"></span>
          </NuxtLink>
        </div>
        <h1
          class="text-white font-semibold text-xl md:text-4xl md:!leading-[150%] lg:text-5xl dark:text-neutral-100 max-w-4xl"
          :title="post?.post_title"
          v-html="post?.post_title"
        ></h1>
        <div
          class="w-full border-b border-neutral-600 dark:border-neutral-700"
        ></div>
        <div
          class="flex flex-col sm:flex-row justify-between sm:items-end space-y-5 sm:space-y-0 sm:space-x-5"
        >
          <div
            class="flex items-center flex-wrap text-neutral-700 text-left dark:text-neutral-200 text-sm leading-none flex-shrink-0"
          >
            <NuxtLink
              class="flex items-center space-x-2"
              :to="localePath('/author/' + post?.author_username)"
            >
              <span
                class="wil-avatar relative flex-shrink-0 inline-flex items-center justify-center overflow-hidden text-neutral-100 uppercase font-semibold shadow-inner rounded-full h-10 w-10 sm:h-11 sm:w-11 text-xl ring-1 ring-white dark:ring-neutral-900"
              >
                <NuxtImg
                  :src="post?.author_avatar"
                  class="absolute inset-0 object-cover w-full h-full"
                  :alt="post?.author_nickname"
                  provider="s3"
                  placeholder="/icons/loading.svg"
                  loading="lazy"
                /><span class="uppercase">
                  {{ post?.author_nickname?.charAt(0) }}
                </span>
              </span>
            </NuxtLink>
            <div class="ml-3">
              <div class="flex items-center">
                <NuxtLink
                  class="block font-semibold text-white"
                  :to="localePath('/author/' + post?.author_username)"
                >
                  {{ post?.author_nickname }}</NuxtLink
                >
              </div>
              <div
                class="text-sm mt-[6px] text-neutral-50 dark:text-neutral-100"
              >
                <span class="">
                  {{ dayjs(post?.post_date).format("MMM DD") }}
                </span>
                <span class="mx-2 font-semibold">·</span>
                <span class="">
                  {{ $t("min_read", { min: post?.reading_time || 0 }) }}
                </span>
              </div>
            </div>
          </div>
          <div class="flex flex-row space-x-2.5 items-center">
            <BlogPostToolbar :post="post" />
          </div>
        </div>
      </div>
    </div>
  </header>
  <div
    class="mt-8 md:mt-0 md:absolute md:top-0 md:right-0 md:bottom-0 md:w-1/2 lg:w-2/5 2xl:w-1/3"
  >
    <div
      class="absolute bottom-0 start-0 top-0 hidden w-1/5 bg-gradient-to-r from-neutral-900 md:block rtl:bg-gradient-to-l dark:from-black"
    ></div>
    <img
      v-if="post?.thumbnail"
      :src="post?.thumbnail"
      class="block w-full h-full object-cover"
      :alt="post.post_name"
    />
  </div>
</template>
<script lang="ts" setup>
import { type Post } from "~/types/Post";
const { locale } = useI18n();
const localePath = useLocalePath();
import("dayjs/locale/vi");
import("dayjs/locale/en");

const dayjs = useDayjs();
//@ts-ignore
dayjs.locale(locale.value);
const props = defineProps({
  post: {
    type: Object as () => Post,
  },
});
</script>
