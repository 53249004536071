<template>
  <div
    class="w-full mt-12 lg:mt-0 lg:w-2/5 lg:pl-10 xl:pl-0 xl:w-1/3 sticky"
    :class="{
      'top-0':!header_display,
      'top-[72px]':header_display
    }"
  >
    <div class="space-y-6" v-if="sidebar?.categories">
      <BlogPostSidebarForm />
      <BlogPostSidebarCategory :categories="sidebar?.categories" />
      <BlogPostSidebarTag :tags="sidebar.tags" />
    </div>
  </div>
</template>
<script lang="ts" setup>
import { useConfigStore } from "~/stores/config";
import type { CategoryShort, PostShort, Tag } from "~/types/Post";

const { locale } = useI18n();
const { header_display } = useConfigStore();
interface ISidebar {
  categories: CategoryShort[];
  posts: PostShort[];
  tags: Tag[];
}
const { data: sidebar } = await useAsyncData(async () => {
  const res: ISidebar = await $fetch("/api/cms/sidebar", {
    method: "post",
    body: {
      locale: locale.value,
    },
  });
  return res;
});
</script>
