<template>
  <Menu as="div" class="relative inline-block text-left">
    <MenuButton
      :titl="$t('show_me_more')"
      class="p-2 text-neutral-500 flex items-center justify-center rounded-lg hover:text-neutral-800 dark:hover:text-neutral-200 sm:hover:bg-neutral-100 dark:hover:bg-neutral-800 focus:outline-none"
    >
      <span class="sr-only">{{$t('show_me_more')}}</span>
      <IconThreeDot class="h-6 w-6" />
    </MenuButton>
    <transition
      enter-active-class="transition duration-100 ease-out"
      enter-from-class="transform scale-95 opacity-0"
      enter-to-class="transform scale-100 opacity-100"
      leave-active-class="transition duration-75 ease-in"
      leave-from-class="transform scale-100 opacity-100"
      leave-to-class="transform scale-95 opacity-0"
    >
      <MenuItems
        class="absolute origin-top-right right-0 w-56 mt-2 bg-white dark:bg-neutral-900 rounded-2xl divide-y divide-neutral-100 shadow-lg ring-1 ring-black dark:ring-white ring-opacity-5 dark:ring-opacity-10 focus:outline-none z-30 transform opacity-100 scale-100"
      >
        <div class="px-1 py-3 text-sm text-neutral-600 dark:text-neutral-300">
          <MenuItem v-if="ownerComment" v-slot="{ active }">
            <button
              @click="eventMore('edit')"
              class="flex items-center rounded-xl w-full px-3 py-2 hover:bg-neutral-100 dark:hover:bg-neutral-800 hover:text-neutral-900 dark:hover:text-neutral-100 truncate focus:outline-none',"
            >
              <Icon
                name="solar:pen-new-square-outline"
                class="w-6 h-6"
                size="24"
              />
              <span class="ml-3">{{ $t("edit") }}</span>
            </button>
          </MenuItem>
          <MenuItem v-if="!ownerComment" v-slot="{ active }">
            <button
              @click="eventMore('reply')"
              class="flex items-center rounded-xl w-full px-3 py-2 hover:bg-neutral-100 dark:hover:bg-neutral-800 hover:text-neutral-900 dark:hover:text-neutral-100 truncate focus:outline-none',"
            >
              <Icon name="solar:reply-outline" class="w-6 h-6" size="24" />
              <span class="ml-3">{{ $t("reply") }}</span>
            </button>
          </MenuItem>
          <MenuItem v-if="!ownerComment" v-slot="{ active }">
            <button
              @click="eventMore('report')"
              class="flex items-center rounded-xl w-full px-3 py-2 hover:bg-neutral-100 dark:hover:bg-neutral-800 hover:text-neutral-900 dark:hover:text-neutral-100 truncate focus:outline-none',"
            >
              <Icon name="solar:flag-outline" class="w-6 h-6" size="24" />
              <span class="ml-3">{{ $t("comment.report") }}</span>
            </button>
          </MenuItem>
          <MenuItem
            v-if="
              ownerComment &&
              (!comment.children || comment.children.length == 0)
            "
            v-slot="{ active }"
          >
            <button
              @click="eventMore('delete')"
              class="flex items-center rounded-xl w-full px-3 py-2 hover:bg-neutral-100 dark:hover:bg-neutral-800 hover:text-neutral-900 dark:hover:text-neutral-100 truncate focus:outline-none',"
            >
              <Icon
                name="solar:trash-bin-trash-outline"
                class="w-6 h-6"
                size="24"
              />
              <span class="ml-3">{{ $t("delete") }}</span>
            </button>
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>
<script lang="ts" setup>
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";
import { type Comment } from "~/types/Comment";
const props = defineProps({
  comment: {
    type: Object as () => Comment,
    required: true,
  },
  ownerComment: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["eventMore"]);
const eventMore = (type: string) => {
  emit("eventMore", type);
};
</script>
