<template>
  <div
    class="scroll-mt-20 max-w-screen-md mx-auto"
    :class="{ 'py-5': !isChild, 'pb-2': isChild }"
  >
    <p
      v-if="showTitle"
      class="text-xl font-semibold text-neutral-800 dark:text-neutral-200"
    >
      <template v-if="type == 'hotel'">
        {{ $t("comment.form_title") }} {{ hotel?.name }}
        <template v-if="hotel?.count_comments"
          >({{ hotel?.count_comments }})</template
        >
      </template>
      <template v-if="type == 'post'">
        {{ $t('leave_your_comment') }}
      </template>
    </p>
    <form @submit.prevent="SubmitComment" :class="{ 'mt-5': !isChild }">
      <textarea
        class="block w-full text-sm rounded-xl border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-600 dark:focus:ring-opacity-25 dark:bg-neutral-900"
        rows="4"
        :placeholder="!isChild ? $t('add_discussion') : $t('add_reply')"
        v-model="contentForm"
        required
      />

      <div class="sm:mt-4 mt-2 space-x-3">
        <button
          class="flex-shrink-0 relative h-auto inline-flex items-center justify-center rounded-full transition-colors border-transparent bg-primary-500 bg-gradient-to-bl from-teal-500 to-secondary-800 text-white text-sm sm:text-base font-medium"
          :class="{
            'py-1.5 px-4 sm:py-2 sm:px-6': size != 'small',
            'py-1 px-3 sm:py-1.5 sm:px-4': size == 'small',
          }"
          type="submit"
        >
          {{ $t("submit") }}
        </button>
        <button
          @click="CancelForm"
          class="flex-shrink-0 relative h-auto inline-flex items-center justify-center rounded-full transition-colors border-transparent bg-neutral-200 dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200 text-sm sm:text-base font-medium"
          :class="{
            'py-1.5 px-4 sm:py-2 sm:px-6': size != 'small',
            'py-1 px-3 sm:py-1.5 sm:px-4': size == 'small',
          }"
          type="button"
        >
          {{ $t("cancel") }}
        </button>
      </div>
    </form>
  </div>
</template>
<script lang="ts" setup>
import { storeToRefs } from "pinia";
import { useAuthStore } from "~/stores/auth";
import { useConfigStore } from "~/stores/config";
import { useHotelStore } from "~/stores/hotel";

const props = defineProps({
  content: {
    type: String,
    default: "",
  },
  size: {
    type: String,
    default: "",
  },
  showTitle: {
    type: Boolean,
    default: true,
  },

  isChild: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
    default: "hotel",
  },
});
watch(
  () => props.content,
  (v) => {
    if (v) {
      contentForm.value = v;
    }
  }
);
const contentForm = ref("");
contentForm.value = props.content;
const { hotel } = useHotelStore();
const { isLoggedIn } = storeToRefs(useAuthStore());
const { auth_modal } = storeToRefs(useConfigStore());
const emit = defineEmits(["CreateComment", "CancelForm"]);
const SubmitComment = async () => {
  if (!isLoggedIn.value) {
    auth_modal.value = true;
  } else {
    emit("CreateComment", { content: contentForm.value });
    contentForm.value = "";
  }
};
const CancelForm = () => {
  contentForm.value = "";
  emit("CancelForm");
};
</script>
