<template>
  <div class="my-4" id="comments">
    <div class="scroll-mt-20 max-w-screen-md pt-5">
      <CoreCommentsForm @CreateComment="CreateComment"  :type="type" />
    </div>
    <div class="max-w-screen-md">
      <div class="space-y-5">
        <div
          v-for="comment in comments"
          class="border border-neutral-100 dark:border-neutral-800 p-4 bg-white dark:bg-transparent rounded-xl"
        >
          <CoreCommentsItem
            :type="type"
            :commentItem="comment"
            @AddComment="AddComment"
            :entity_id="entity_id"
          />
          <div
            class="children-comments mt-4 pl-4 pr-4 border-l border-l-neutral-300 dark:border-l-neutral-700 ml-4 h-full"
          >
            <div class="flex flex-col gap-y-2">
              <CoreCommentsItem
                :type="type"
                :size="'small'"
                v-for="commentChild in comment.children"
                :commentItem="commentChild"
                @AddComment="AddComment"
                :entity_id="entity_id"
              />
            </div>
          </div>
        </div>
        <CoreCommentsEditModal @SubmitComment="EditComment" />
        <CoreCommentsDeleteModal @DeleteComment="DeleteComment" />
        <CoreCommentsReportModal @ReportComment="ReportComment" />
      </div>
      <div class="mt-4 mb-4" v-if="count_comments > 0">
        <button
          @click="GetComments"
          class="flex-shrink-0 disabled:opacity-70 disabled:pointer-events-none relative h-auto inline-flex items-center justify-center rounded-full transition-colors border-transparent bg-teal-700 hover:bg-teal-600 text-teal-50 text-sm sm:text-base font-medium py-2 px-4 sm:py-3 sm:px-6 dark:bg-teal-700 w-full"
          :disabled="loading"
        >
          <IconLoading v-if="loading" class="w-5 h-5 mr-2 animate-spin" />
          <span> View more comments ({{ count_comments }} comments)</span>
        </button>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { storeToRefs } from "pinia";
import { useHotelStore } from "~/stores/hotel";
import { type Comment } from "~/types/Comment";
const props = defineProps({
  comments: {
    type: Object as () => Comment[],
    required: true,
  },
  count_comments: {
    type: Number,
    default: 0,
  },
  entity_id: {
    type: Number || undefined,
    required: true,
  },
  type: {
    type: String,
    required: true,
  },
  loading: {
    type: Boolean,
    default: false,
  },
});
const hotelStore = useHotelStore();
const {
  comment,
  edit_comment_modal,
  delete_comment_modal,
  report_comment_modal,
} = storeToRefs(hotelStore);
const emit = defineEmits([
  "AddComment",
  "DeleteComment",
  "EditComment",
  "CreateComment",
  "eventLoadComments",
]);

const AddComment = (data: { comment_id: number; comment: Comment }) => {
  emit("AddComment", data);
};

const DeleteComment = () => {
  delete_comment_modal.value = false;
  if (comment.value) {
    useApiFetch("/api/user/delete-comment", {
      body: { comment_id: comment.value.id },
      method: "POST",
    }).then((res) => {
      emit("DeleteComment", res.data.value);
    });
  }
};
const EditComment = (content: string) => {
  if (comment.value) {
    useApiFetch("/api/user/edit-comment", {
      body: { content, comment_id: comment.value.id },
      method: "POST",
    }).then((res) => {
      edit_comment_modal.value = false;
      if (res.data.value) {
        emit("EditComment", content);
      }
      // comment.value = null;
    });
  } else {
    edit_comment_modal.value = false;
  }
};
const ReportComment = (data: { message: string; reason: string ,token:string}) => {
  report_comment_modal.value = false;
  if (comment.value) {
    useApiFetch("/api/report-comment", {
      body: { ...data, comment_id: comment.value?.id },
      method: "POST",
    });
  }
};
const CreateComment = (data:{content: string,token:string}) => {
  useApiFetch("/api/user/comment", {
    method: "POST",
    body: {
      entity_id: props.entity_id,
      type: props.type,
      content: data.content,
      token:data.token
    },
  }).then((res) => {
    if (res.data.value) {
      const comRes = res.data.value as unknown as Comment;
      emit("CreateComment", comRes);
    }
  });
};
const page = ref(1);
const GetComments = () => {
  emit("eventLoadComments");
};
</script>
