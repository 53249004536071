<template>
  <a
    href="#comments"
    class="relative items-center min-w-[68px] rounded-full text-neutral-6000 bg-neutral-50 transition-colors dark:text-neutral-200 dark:bg-neutral-800 hover:bg-teal-50 dark:hover:bg-teal-100 hover:text-teal-600 dark:hover:text-teal-500 flex px-4 h-9 text-sm"
  >
    <IconComment />
    <span class="ml-1">{{ post?.comment_counts || 0 }}</span>
  </a>
  <div class="px-1">
    <div class="border-l border-neutral-200 dark:border-neutral-700 h-6"></div>
  </div>
  <button
    @click="Archived"
    class="relative rounded-full flex items-center justify-center h-9 w-9 bg-neutral-100 hover:bg-neutral-200 dark:bg-neutral-800 dark:hover:bg-neutral-700 dark:text-neutral-200"
    :title="$t('save_to_read_list')"
    :class="{ 'bg-yellow-100 text-yellow-600': entityArchived }"
  >
  <span class="sr-only">{{ $t('save_to_read_list') }}</span>
    <IconBookmark
      class="w-[18px] h-[18px]"
      :class="{ 'fill-yellow-600': entityArchived }"
    />
  </button>
  <button
    class="relative rounded-full flex items-center justify-center h-9 w-9 bg-neutral-100 hover:bg-neutral-200 dark:bg-neutral-800 dark:hover:bg-neutral-700 dark:text-neutral-200"
    :title="$t('share')"
    @click="shareNavigator"
  >
  <span class="sr-only">{{$t('share')}}</span>
    <IconShare class="w-5 h-5" />
  </button>
  <button
    :title="$t('report')"
    @click="reportPost"
    class="relative rounded-full flex items-center justify-center h-9 w-9 bg-neutral-100 hover:bg-neutral-200 dark:bg-neutral-800 dark:hover:bg-neutral-700 dark:text-neutral-200"
  >
  <span class="sr-only">{{$t('report')}}</span>
    <IconFlag class="w-5 h-5" />
  </button>
  <LazyGeneralReportModal/>
</template>
<script lang="ts" setup>
import { storeToRefs } from "pinia";
import { useAuthStore } from "~/stores/auth";
import { useCmsStore } from "~/stores/cms";
import { useConfigStore } from "~/stores/config";
import { type Post } from "~/types/Post";

const { ArchivedPost } = useCmsStore();
const { user, isLoggedIn } = useAuthStore();
const { auth_modal,report_modal } = storeToRefs(useConfigStore());
const entityArchived = ref(false);
const props = defineProps({
  post: {
    type: Object as () => Post,
  },
});
const shareNavigator = () => {
  useHelper().shareNavigator();
};
const Archived = () => {
  if (!isLoggedIn) {
    auth_modal.value = true;
    return;
  }
  if (props.post?.ID) {
    ArchivedPost(props.post?.ID);
    entityArchived.value = true;
  }
};
onMounted(() => {
  if (isLoggedIn) {
    user?.archived_post?.forEach((item) => {
      if (item == props.post?.ID) {
        entityArchived.value = true;
      }
    });
  }
});
const reportPost = ()=>{
  report_modal.value = true
}
</script>
