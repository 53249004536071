<script setup lang="ts">
import { useAds } from '~/composables/ads';

const { redirect } = useBooking();
const openLink = (url: string) => {
  return redirect(url);
};

const { randAds } = useAds();
const images = randAds(2);
</script>
<template>
  <div
    class="grid grid-cols-1 sm:grid-cols-2 sm:space-x-4 space-y-4 sm:space-y-0"
  >
    <NuxtImg
      v-for="img in images"
      :src="img.image"
      format="webp"
      width="800"
      @click="openLink(img.url)"
      alt="promo-thailand-banner"
      class="rounded-lg overflow-hidden cursor-pointer"
    ></NuxtImg>
  </div>
</template>
