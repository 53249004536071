<template>
  <TransitionRoot appear :show="report_comment_modal" as="template">
    <Dialog
      as="div"
      @close="closeModal"
      class="fixed inset-0 z-50 overflow-y-auto"
    >
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black bg-opacity-25" />
      </TransitionChild>

      <div class="min-h-screen px-1 text-center md:px-4">
        <div
          class="fixed inset-0 bg-neutral-900 bg-opacity-50 dark:bg-opacity-80 opacity-100 flex items-center justify-center"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="inline-block w-full my-5 overflow-hidden text-left align-middle transition-all transform bg-white border border-black border-opacity-5 shadow-xl rounded-2xl sm:my-8 dark:bg-neutral-800 dark:border-neutral-700 text-neutral-900 dark:text-neutral-300 max-w-screen-md opacity-100 scale-100"
            >
              <DialogTitle
                as="div"
                class="py-4 px-6 text-center relative border-b border-neutral-100 dark:border-neutral-700 md:py-5"
              >
                <button
                  type="button"
                  class="w-8 h-8 flex items-center justify-center rounded-full text-neutral-700 dark:text-neutral-300 hover:bg-neutral-100 dark:hover:bg-neutral-700 absolute left-2 top-1/2 transform -translate-y-1/2 sm:left-4 focus:outline-none"
                  @click="report_comment_modal = false"
                >
                  <span class="sr-only">{{ $t("close") }}</span>
                  <IconX class="w-5 h-5" />
                </button>
                <h3
                  class="text-base font-semibold text-neutral-900 lg:text-xl dark:text-neutral-200 mx-10"
                >
                  {{ $t("comment.report") }}
                </h3>
              </DialogTitle>
              <div class="py-4 px-6 md:py-5">
                <form @submit.prevent="ReportComment">
                  <RadioGroup v-model="reason">
                    <RadioGroupLabel class="sr-only">{{
                      $t("comment.report")
                    }}</RadioGroupLabel>
                    <div class="grid grid-cols-2 lg:grid-cols-4 gap-2">
                      <RadioGroupOption
                        as="template"
                        v-for="plan in plans"
                        :key="plan.value"
                        :value="plan.value"
                        v-slot="{ active, checked }"
                      >
                        <button
                          type="button"
                          class="relative shadow-lg rounded-lg px-3 py-3 cursor-pointer flex sm:px-5 sm:py-4 focus:outline-none"
                          :class="{
                            'bg-teal-600': checked,
                            'bg-white': !checked,
                          }"
                        >
                          <div class="flex items-center justify-between w-full">
                            <div class="flex items-center">
                              <div class="text-sm">
                                <RadioGroupLabel
                                  as="p"
                                  class="font-medium line-clamp-1"
                                  :class="{
                                    'text-neutral-50': checked,
                                    'text-neutral-900': !checked,
                                  }"
                                >
                                  {{ plan.label }}
                                </RadioGroupLabel>
                              </div>
                            </div>
                          </div>
                          <div v-if="checked" class="flex-shrink-0 text-white">
                            <svg
                              class="w-6 h-6"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <circle
                                cx="12"
                                cy="12"
                                r="12"
                                fill="#fff"
                                opacity="0.2"
                              ></circle>
                              <path
                                d="M7 13l3 3 7-7"
                                stroke="#fff"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                            </svg>
                          </div>
                        </button>
                      </RadioGroupOption>
                    </div>
                  </RadioGroup>
                  <div class="mt-4">
                    <h4
                      class="text-lg font-semibold text-neutral-700 dark:text-neutral-200"
                    >
                      {{ $t("message") }}
                    </h4>
                    <p class="text-sm text-neutral-600 dark:text-neutral-400">
                      {{ $t("comment.report_msg") }}
                    </p>
                    <textarea
                      v-model="message"
                      class="block w-full text-sm rounded-xl border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-600 dark:focus:ring-opacity-25 dark:bg-neutral-900 mt-3"
                      rows="4"
                      required
                    />
                  </div>
                  <div class="mt-3 space-x-4">
                    <button
                      class="py-2 px-3 sm:py-3 sm:px-6 flex-shrink-0 relative h-auto inline-flex items-center justify-center rounded-full transition-colors border-transparent bg-teal-700 hover:bg-teal-600 text-teal-50 text-sm sm:text-base font-medium"
                      type="submit"
                    >
                      {{ $t("submit") }}
                    </button>
                    <button
                      @click="report_comment_modal = false"
                      class="py-2 px-3 sm:py-3 sm:px-6 flex-shrink-0 relative h-auto inline-flex items-center justify-center rounded-full transition-colors border-transparent bg-neutral-200 dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200 text-sm sm:text-base font-medium"
                      type="button"
                    >
                      {{ $t("cancel") }}
                    </button>
                  </div>
                </form>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script lang="ts" setup>
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
  RadioGroup,
  RadioGroupOption,
  RadioGroupLabel,
} from "@headlessui/vue";
import { storeToRefs } from "pinia";
import { useHotelStore } from "~/stores/hotel";

const { report_comment_modal, comment } = storeToRefs(useHotelStore());
const message = ref("");
const reason = ref("inappropriate");
const { t } = useI18n();
const plans = ref([
  { label: t("inappropriate"), value: "inappropriate" },
  { label: t("violence"), value: "violence" },
  { label: t("spam"), value: "spam" },
  { label: t("other"), value: "other" },
]);


function closeModal() {
  report_comment_modal.value = false;
}
const emit = defineEmits(["ReportComment"]);
const ReportComment = async () => {
  emit("ReportComment", {
    message: message.value,
    reason: reason.value,
  });
};
</script>
