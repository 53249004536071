<template>
  <div class="flex">
    <div
      class="relative flex-shrink-0 inline-flex items-center justify-center overflow-hidden text-neutral-100 uppercase font-semibold shadow-inner rounded-full h-6 w-6 text-base sm:text-lg sm:h-8 sm:w-8"
    >
      <template v-if="commentItem.user && commentItem.user?.avatar">
        <NuxtImg
          :src="commentItem.user?.avatar"
          provider="glide"
          width="100px"
          format="webp"
          class="absolute inset-0 w-full h-full object-cover"
          :alt="commentItem.user.full_name"
        />
      </template>
      <template v-else>
        <NuxtImg
          src="/icons/user-default.jpg"
          format="webp"
          width="100"
          :alt="'user-default-' + commentItem.user?.full_name"
        />
      </template>
    </div>
    <div class="flex-grow flex flex-col ml-2 text-sm sm:ml-3 sm:text-base">
      <div class="relative pr-6">
        <div class="absolute -right-3 -top-3">
          <CoreCommentsMore
            :comment="commentItem"
            :ownerComment="ownerComment"
            @eventMore="eventMore"
          />
        </div>
        <div class="flex items-center">
          <template v-if="ownerComment">
            <p
              class="flex-shrink-0 font-semibold text-primary-700 dark:text-primary-600"
            >
              {{ commentItem.user?.full_name }}
              <span class="italic text-xs font-light"> (you) </span>
            </p>
          </template>
          <a
            v-else
            class="flex-shrink-0 font-semibold text-neutral-800 dark:text-neutral-100"
            href="/"
            >{{ commentItem.user?.full_name }}
          </a>
          <span class="mx-2">·</span>
          <span
            class="text-neutral-500 dark:text-neutral-400 text-xs line-clamp-1"
            :class="{ 'sm:text-sm': size == 'small' }"
            >{{ dayjs(commentItem.created_at).fromNow() }}</span
          >
        </div>
        <div
          v-if="commentItem.reply_user"
          class="text-xs text-neutral-600 font-light"
        >
          Replying to
          <span class="font-medium hover:text-primary-700 cursor-pointer"
            >@{{ commentItem.reply_user?.full_name }}</span
          >
        </div>
      </div>
      <div
        class="block text-neutral-700 mt-2 mb-3 sm:mt-3 sm:mb-4 dark:text-neutral-300 whitespace-break-spaces"
      >
        {{ commentItem.content }}
      </div>
      <div class="w-full" v-if="isReply">
        <CoreCommentsForm
          @cancelForm="isReply = false"
          :showTitle="false"
          :isChild="true"
          :size="'small'"
          @create-comment="submitComment"
        />
      </div>
      <div v-else-if="!ownerComment" class="flex items-center space-x-2">
        <button
          class="flex items-center min-w-[68px] rounded-full text-neutral-600 bg-neutral-100 dark:text-neutral-200 dark:bg-neutral-800 px-3 h-8 hover:bg-primary-50 hover:text-primary-600 dark:hover:text-primary-500 focus:outline-none"
          title="Reply"
          @click="isReply = true"
        >
          <Icon name="solar:reply-outline" class="w-[18px] mr-2 h-[18px]" />
          <span
            class="text-xs leading-none text-neutral-900 dark:text-neutral-200"
            >{{ $t("reply") }}</span
          >
        </button>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { storeToRefs } from "pinia";
import { useAuthStore } from "~/stores/auth";
import { useHotelStore } from "~/stores/hotel";
import { type Comment } from "~/types/Comment";
import "dayjs/locale/en";
import "dayjs/locale/vi";
const { locale } = useI18n();
const isReply = ref(false);
const props = defineProps({
  commentItem: {
    type: Object as () => Comment,
    required: true,
  },
  size: {
    type: String,
    default: "",
  },
  type: {
    type: String,
    required: true,
  },
  entity_id: {
    type: Number,
  },
});
const {
  comment,
  edit_comment_modal,
  delete_comment_modal,
  report_comment_modal,
} = storeToRefs(useHotelStore());
const dayjs = useDayjs();
dayjs.locale(locale.value);

const { user } = useAuthStore();
const ownerComment = computed(() => {
  if (user && user.id == props.commentItem.user_id) {
    return true;
  }
  return false;
});
const eventMore = (event: string) => {
  switch (event) {
    case "edit":
      comment.value = props.commentItem;
      edit_comment_modal.value = true;
      break;
    case "delete":
      comment.value = props.commentItem;
      delete_comment_modal.value = true;
      break;
    case "reply":
      isReply.value = true;
      break;
    case "report":
      comment.value = props.commentItem;
      report_comment_modal.value = true;
      break;
  }
};
const emit = defineEmits(["AddComment"]);
const submitComment = (content: string) => {
  //
  isReply.value = false;

  useApiFetch("/api/user/comment", {
    method: "POST",
    body: {
      reply_comment_id: props.commentItem.id,
      entity_id: props.entity_id,
      type: props.type,
      content: content,
    },
  }).then((res) => {
    if (res.data.value) {
      const comRes = res.data.value as unknown as Comment;
      if (!props.commentItem.parent_id) {
        emit("AddComment", {
          comment_id: props.commentItem.id,
          comment: comRes,
        });
      } else {
        emit("AddComment", {
          comment_id: props.commentItem.parent_id,
          comment: comRes,
        });
      }
    }
  });
  return;
};
</script>
