<template>
  <BlogPostSidebarTemp>
    <template #header>
      {{ $t("more_tag") }}
    </template>
    <div class="flex flex-wrap">
      <NuxtLink
        :to="localePath('/tag/' + tag.slug)"
        v-for="tag in tags"
        class="inline-block bg-neutral-200 hover:bg-neutral-50 text-sm text-neutral-600 dark:text-neutral-300 py-2 px-3 rounded-lg md:py-2.5 md:px-4 dark:bg-neutral-900 mr-2 mb-2"
      >
        {{ tag.name }}
      </NuxtLink>
    </div>
  </BlogPostSidebarTemp>
</template>
<script lang="ts" setup>
import type { Tag } from "~/types/Post";

const localePath = useLocalePath();
const props = defineProps({
  tags: {
    type: Array as () => Tag[],
    required: true,
  },
});
</script>
