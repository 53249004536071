<template>
  <div class="max-w-screen-md flex flex-wrap">
    <a
      :href="route.fullPath"
      v-for="tag in post?.tags"
      class="inline-block bg-white hover:bg-neutral-50 text-sm text-neutral-600 dark:text-neutral-300 py-2 px-3 rounded-lg md:py-2.5 md:px-4 dark:bg-neutral-900 mr-2 mb-2"
    >
      {{ tag.name }}
    </a>
  </div>
</template>
<script lang="ts" setup>
import { type Post } from "~/types/Post";
const props = defineProps({
  post: {
    type: Object as () => Post,
  },
});
const route = useRoute();
</script>
