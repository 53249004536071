<template>
  <BlogPostSidebarTemp>
    <template #header>
      {{ $t("trending_topic") }}
    </template>
    <div
      class="flex flex-col divide-y divide-neutral-200 dark:divide-neutral-700"
    >
      <div
        v-for="category in categories"
        class="flex items-center p-4 xl:p-5 hover:bg-neutral-200 dark:hover:bg-neutral-700"
      >
        <!-- <div
          class="relative flex-shrink-0 w-12 h-12 rounded-lg mr-4 overflow-hidden"
        >
          <NuxtLink :to="localePath('/topic/' + category.slug)">
            <NuxtImg
              v-if="category.acf_thumbnail"
              :src="category.acf_thumbnail"
              :alt="category.slug"
              class="object-cover absolute inset-0 w-full h-full"
            />
            <NuxtImg
              v-else
              src="/icons/loading.svg"
              :alt="'default-'+category.slug"
              class="object-cover absolute inset-0 w-full h-full"
            />
          </NuxtLink>
        </div> -->
        <div>
          <h2
            class="text-neutral-900 dark:text-neutral-100 text-sm sm:text-base font-medium sm:font-semibold"
          >
            <NuxtLink :to="localePath('/topic/' + category.slug)" :title="category.name">
              <span v-html="category.name"></span>
            </NuxtLink>
          </h2>
          <span
            class="text-xs block mt-[2px] text-neutral-800 dark:text-neutral-400"
            >{{ category.count_posts }} {{$t('articles')}}</span
          >
        </div>
      </div>
    </div>
  </BlogPostSidebarTemp>
</template>
<script lang="ts" setup>
import type { CategoryShort } from "~/types/Post";
const localePath = useLocalePath();
const props = defineProps({
  categories: {
    type: Array as () => CategoryShort[],
    required: true,
  },
});
</script>
